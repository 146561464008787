/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

//bootstrap
import "bootstrap/dist/css/bootstrap.css";

// import "./src/components/homepagenew/common.scss";
import "./src/components/common/fonts.scss";

export function shouldUpdateScroll() {
  return false;
}
