exports.components = {
  "component---src-components-store-landing-js": () => import("./../../../src/components/store/Landing.js" /* webpackChunkName: "component---src-components-store-landing-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-order-failed-js": () => import("./../../../src/pages/order/failed.js" /* webpackChunkName: "component---src-pages-order-failed-js" */),
  "component---src-pages-order-success-js": () => import("./../../../src/pages/order/success.js" /* webpackChunkName: "component---src-pages-order-success-js" */),
  "component---src-pages-product-product-url-slug-js": () => import("./../../../src/pages/product/[...productUrlSlug].js" /* webpackChunkName: "component---src-pages-product-product-url-slug-js" */)
}

